<template>
  <b-container>
    <b-row>
      <b-col cols="4" sm="6" md="7" class="examination-title mr-auto p-3">
        {{ reportName() }}
      </b-col>
      <b-col class="examination-right p-3">
        <b-row>
          <b-col>
            <!-- <span class="iconfont icon-jiexi"></span>
            <span>题意解析</span> -->
          </b-col>
          <b-col @click="downPdf">
            <span class="iconfont icon-xiazai"></span>
            <span>下载体检报告</span>
          </b-col>
          <b-col @click="returnHome">
            <span class="iconfont icon-fanhui1"> </span>
            <span>返回大本营</span>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="user-info">
      <div>
        姓名：<span>{{ userInfo.studentName }}</span>
      </div>
      <div>
        学校：<span>{{ result.schoolName }}</span>
      </div>
      <div>
        班级：<span>{{ result.className }}</span>
      </div>
      <div>
        学科：<span>{{ result.subjectName }}</span>
      </div>
      <div>
        报告创建时间：<span>{{ result.creationTime }}</span>
      </div>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  props: {
    result: {
      default: function () {
        return {};
      },
    },
    smallModule: {
      default: function () {
        return "";
      },
    },
  },
  computed: {
    ...mapState(["mockTestName","userInfo"]),
  },
  methods: {
    downPdf(){
      this.$emit('downPdf')
    },
    returnHome() {
      this.$router.replace({
        name: "HomeIndex",
      });
    },
    reportName() {
      if (this.$route.query.sprintId == 1) {
        return "培立优中考英语冲刺战备体检报告";
      }
      if (this.$route.query.sprintId == 2 && this.$route.query.stageId == 1) {
        return "培立优中考英语冲刺扫雷尖兵-词汇部分靶向诊断报告";
      }
      if (this.$route.query.sprintId == 2 && this.$route.query.stageId == 3) {
        return "培立优中考英语冲刺扫雷尖兵-词汇部分效果测评报告";
      }
      if (this.$route.query.sprintId == 4 && this.$route.query.stageId == 1) {
        return "培立优中考英语冲刺沙漠行动靶向诊断报告";
      }
      if (this.$route.query.sprintId == 4 && this.$route.query.stageId == 3) {
        return "培立优中考英语冲刺沙漠行动效果测评报告";
      }
      if (this.$route.query.sprintId == 7) {
        return `培立优${this.mockTestName}测评报告`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.examination-title {
  font-size: 1.15vw;
  color: $text-green;
  text-align: left;
  font-weight: bold;
}
.examination-right {
  line-height: 2;
  color: $text-light-gray;
  cursor: pointer;
  .iconfont {
    font-weight: bold;
    padding-right: 3px;
  }
}
.user-info {
  text-align: left;
  padding-top: 2rem;
  padding-bottom: 1.5rem;
  color: $text-light-gray;
  border-bottom: 1px solid #e5e5e5;
  & > div {
    padding-right: 2.4vw;
    padding-left: 0.9vw;
    span {
      font-weight: bold;
    }
  }
}
</style>