<template>
  <div class="eng-form">
    <b-container>
      <div v-for="(score, index) in score.questionScores" :key="index"></div>
      <b-row align-v="center">
        <div class="iconfont icon-defen1"></div>
        <span class="score-test">得分分布</span>
      </b-row>
      <b-row align-h="center" class="circular-progress">
        <b-col sm="3" v-show="score.listeningFullScore !== 0">
          <EndCanvas
            :score="score.listeningAnswerScore"
            :totalScore="score.listeningFullScore"
            :scoreTest="listeningTest"
            :canvasId="listeningId"
            :fillingColor="listeningColor"
          >
          </EndCanvas>
        </b-col>
        <b-col sm="3" v-show="score.objectiveFullScore !== 0">
          <EndCanvas
            :score="score.objectiveAnswerScore"
            :totalScore="score.objectiveFullScore"
            :scoreTest="objectiveTest"
            :canvasId="subjectiveId"
            :fillingColor="subjectiveColor"
          >
          </EndCanvas>
        </b-col>

        <b-col sm="3" v-show="score.subjectiveFullScore !== 0">
          <EndCanvas
            :score="score.subjectiveAnswerScore"
            :totalScore="score.subjectiveFullScore"
            :scoreTest="subjectiveTest"
            :canvasId="objectiveId"
            :fillingColor="objectiveColor"
          >
          </EndCanvas>
        </b-col>
      </b-row>
      <b-row class="from-data">
        <b-col>
          <div border="1" v-if="score.questionScores">
            <div class="title-text">
              <div>题号</div>
              <div class="question-number">
                <div
                  v-for="(topic, index) in (score.questionScores || '').slice(0, 20)"
                  :key="index"
                  :class="topicColor(topic.questionType)"
                >
                  <span>{{ topic.questionNumber }}</span>
                </div>
              </div>
            </div>
            <div class="title-text">
              <div>得分</div>
              <div class="form-score">
                <div
                  v-for="(topic, index) in (score.questionScores || '').slice(0, 20)"
                  :key="index"
                  :class="topic.score == 0 ? 'test-color' : ''"
                >
                  {{ topic.score }}
                </div>
              </div>
            </div>
            <div class="title-text">
              <div v-show="score.questionScores.length <= 20 ? false : true">题号</div>
              <div class="question-number">
                <div
                  v-for="(topic, index) in (score.questionScores || '').slice(20, 40)"
                  :key="index"
                  :class="topicColor(topic.questionType)"
                >
                  <span>{{ topic.questionNumber }}</span>
                </div>
              </div>
            </div>
            <div class="title-text">
              <div v-show="score.questionScores.length <= 20 ? false : true">得分</div>
              <div class="form-score">
                <div
                  v-for="(topic, index) in (score.questionScores || '').slice(20, 40)"
                  :key="index"
                  :class="topic.score == 0 ? 'test-color' : ''"
                >
                  {{ topic.score }}
                </div>
              </div>
            </div>
            <div class="title-text">
              <div v-show="score.questionScores.length <= 40 ? false : true">题号</div>
              <div class="question-number">
                <div
                  v-for="(topic, index) in (score.questionScores || '').slice(40, 60)"
                  :key="index"
                  :class="topicColor(topic.questionType)"
                >
                  <span>{{ topic.questionNumber }}</span>
                </div>
              </div>
            </div>
            <div class="title-text">
              <div v-show="score.questionScores.length <= 40 ? false : true">得分</div>
              <div class="form-score">
                <div
                  v-for="(topic, index) in (score.questionScores || '').slice(40, 60)"
                  :key="index"
                  :class="topic.score == 0 ? 'test-color' : ''"
                >
                  {{ topic.score }}
                </div>
              </div>
            </div>
            <div class="title-text">
              <div v-show="score.questionScores.length <= 60 ? false : true">题号</div>
              <div class="question-number">
                <div
                  v-for="(topic, index) in (score.questionScores || '').slice(60,80)"
                  :key="index"
                  :class="topicColor(topic.questionType)"
                >
                  <span>{{ topic.questionNumber }}</span>
                </div>
              </div>
            </div>
            <div class="title-text">
              <div v-show="score.questionScores.length <= 60 ? false : true">得分</div>
              <div class="form-score">
                <div
                  v-for="(topic, index) in (score.questionScores || '').slice(60,80)"
                  :key="index"
                  :class="topic.score == 0 ? 'test-color' : ''"
                >
                  {{ topic.score }}
                </div>
              </div>
            </div>
            <div class="title-text">
              <div v-show="score.questionScores.length <= 80 ? false : true">题号</div>
              <div class="question-number">
                <div
                  v-for="(topic, index) in (score.questionScores || '').slice(80)"
                  :key="index"
                  :class="topicColor(topic.questionType)"
                >
                  <span>{{ topic.questionNumber }}</span>
                </div>
              </div>
            </div>
            <div class="title-text">
              <div v-show="score.questionScores.length <= 80 ? false : true">得分</div>
              <div class="form-score">
                <div
                  v-for="(topic, index) in (score.questionScores || '').slice(80)"
                  :key="index"
                  :class="topic.score == 0 ? 'test-color' : ''"
                >
                  {{ topic.score }}
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import EndCanvas from "../english-end/end-canvas.vue";
export default {
  data() {
    return {
      subjectiveId: "subjectiveId",
      objectiveId: "objectiveId",
      listeningId: "ringId",
    };
  },
  props: {
    score: {
      default: function () {
        return {};
      },
    },
    objectiveTest: {
      default: "",
    },
    subjectiveTest: {
      default: "",
    },
    listeningTest: {
      default: "",
    },
    subjectiveColor: {
      default: "",
    },
    objectiveColor: {
      default: "",
    },
    listeningColor: {
      default: "",
    },
    ringDisplay: { type: String, required: false },
    answerQuestions: {
      default: 0,
    },
  },
  components: {
    EndCanvas,
  },
  mounted() {
    console.log("subjectiveColor", this.subjectiveColor);
    console.log("objectiveColor", this.objectiveColor);
  },
  methods: {
    topicColor(questionType) {
      if (questionType == 1) {
        return "hearing-color";
      }
      if (questionType == 2) {
        return "numberColor";
      }
      if (questionType == 3) {
        return "host-color";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.eng-form {
  color: $text-light-gray;
  .icon-defen1 {
    color: $text-green;
    font-size: 1.6rem;
  }
  .score-test {
    font-weight: bold;
  }
  .circular-progress {
    padding-bottom: 3rem;
    .objective-progress,
    .subjective-progress {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .progress-test {
        position: absolute;
      }
    }
  }
  .from-data {
    .title-text,
    .question-number,
    .form-score {
      display: flex;
      width: 100%;
    }
    .title-text {
      & > div:nth-child(1) {
        width: 5%;
        height: 3.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #e5e5e5;
      }
      .question-number-text {
        background-color: #2cb774;
        color: #fff;
      }
      .question-number,
      .form-score {
        margin: 0;
        padding: 0;
        & > div {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 5%;
          height: 3.5rem;
          border-right: 1px solid #e5e5e5;
          border-bottom: 1px solid #e5e5e5;
        }
      }
      .question-number {
        & > div {
          border-right: 1px solid #e5e5e5;
          border-bottom: 1px solid #e5e5e5;
          color: #fff;
        }
        .hearing-color {
          background-color: #fb8f67;
        }
        .numberColor {
          background-color: #2cb774;
        }
        .host-color {
          background-color: #ffc13b;
        }
      }
      .form-score {
        & > div {
          border-right: 1px solid #e5e5e5;
          border-bottom: 1px solid #e5e5e5;
          font-weight: bold;
          font-size: 16px;
        }
      }
    }
    .test-color {
      color: $text-red;
    }
    .questionNumber-bg {
      background-color: #ffc13b;
    }
  }
}
</style>