<template>
  <b-container>
    <b-row align-h="center">
      <b-col sm="3" class="ring">
        <canvas :id="canvasId"></canvas>
        <span class="objective-fraction">
          <slot>
            <span class="number"> {{ score }}/{{ totalScore }} </span>
            <div>{{ this.scoreTest }}</div>
          </slot>
        </span>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    score: {
      default: 0
    },
    totalScore: {
      default: 0
    },
    scoreTest: {
      default: function () {
        return {};
      },
    },
    canvasId: {
      default: "",
    },
    fillingColor: {
      default: "",
    },
  },
  watch:{
    score:{
      handler(){
        this.objective()
      }
    }
  },
  mounted() {
    this.objective();
    console.log("fillingColor", this.fillingColor);
  },
  methods: {
    objective() {
      let radius = 70; //外环半径
      let thickness = 6; //圆环厚度
      let innerRadius = radius - thickness; //内环半径
      let startAngle = 0; //开始角度
      let endAngle = 360; //结束角度
      let x = 0; //圆心x坐标
      let y = 0; //圆心y坐标
      let canvas = document.getElementById(this.canvasId);
      canvas.width = 300;
      canvas.height = 300;

      let ctx = canvas.getContext("2d");
      ctx.translate(canvas.width / 2, canvas.height / 2); //将绘图原点移到画布中央
      ctx.rotate(angle2Radian(225)); //将画布旋转225度
      ctx.fillStyle = "#eee"; //初始填充颜色
      renderRing(startAngle, endAngle);

      //渲染函数
      function renderRing(startAngle, endAngle) {
        ctx.beginPath();

        //绘制外环
        ctx.arc(x, y, radius, angle2Radian(startAngle), angle2Radian(endAngle));

        //计算外环与内环第一个连接处的中心坐标
        let oneCtrlPoint = calcRingPoint(
          x,
          y,
          innerRadius + thickness / 2,
          endAngle
        );

        //绘制外环与内环第一个连接处的圆环
        ctx.arc(
          oneCtrlPoint.x,
          oneCtrlPoint.y,
          thickness / 2,
          angle2Radian(-90),
          angle2Radian(270)
        );

        // //绘制内环
        ctx.arc(
          x,
          y,
          innerRadius,
          angle2Radian(endAngle),
          angle2Radian(startAngle),
          true
        );

        //计算外环与内环第二个连接处的中心坐标
        let twoCtrlPoint = calcRingPoint(
          x,
          y,
          innerRadius + thickness / 2,
          startAngle
        );

        //绘制外环与内环第二个连接处的圆环
        ctx.arc(
          twoCtrlPoint.x,
          twoCtrlPoint.y,
          thickness / 2,
          angle2Radian(-90),
          angle2Radian(270)
        );

        ctx.fill();
        // ctx.stroke()
      }

      //计算圆环上点的坐标
      function calcRingPoint(x, y, radius, angle) {
        let res = {};
        res.x = x + radius * Math.cos((angle * Math.PI) / 180);
        res.y = y + radius * Math.sin((angle * Math.PI) / 180);
        return res;
      }

      //弧度转角度
      // function radian2Angle(radian) {
      //   return (180 * radian) / Math.PI;
      // }

      //角度转弧度
      function angle2Radian(angle) {
        return (angle * Math.PI) / 180;
      }

      //进度条颜色
      var lingrad = ctx.createLinearGradient(0, 0, 150, 0);
      lingrad.addColorStop(0, this.fillingColor);
      //   lingrad.addColorStop(1, "#fff");
      ctx.fillStyle = lingrad;

      //开始绘画
      let tempAngle = startAngle;
      let total = this.totalScore; //总分
      let now = this.score; //当前分数
      console.log('score',now)
      let percent = now / total; //百分比
      let twoEndAngle = percent * 360 + startAngle;
      let step = (twoEndAngle - startAngle) / 80;
      // let numberSpan = document.querySelector(".number");
      let inter = setInterval(() => {
        if (tempAngle > twoEndAngle) {
          clearInterval(inter);
        } else {
          // numberSpan.innerText = percent * 100;
          tempAngle += step;
        }
        renderRing(startAngle, tempAngle);
      }, 20);
    },
  },
};
</script>

<style lang="scss" scoped>
.ring {
  width: 137px;
  height: 107px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.objective-fraction,
.subjective-fraction {
  position: absolute;
  font-size: 18px;
  font-weight: bold;
  & > div:nth-child(1){
    margin: 0;
  }
}
</style>