import { axios } from '@/utils/request'

const api = {
  GetExamSectionsAsync: "/services/app/EnglishCommon/GetExamSectionsAsync",
  GetEngExamQuestionInfos: "/services/app/EnglishCommon/GetEngExamQuestionInfos",
  SaveEngExam: "/services/app/EnglishCommon/SaveEngExam",
  GetEngReport: "/services/app/EnglishReport/GetEngReport",
  GetSectionAnswerRecords: "/services/app/EnglishReport/GetSectionAnswerRecords",
  GetExamRecord: '/services/app/EnglishListening/GetExamRecord',
  GetOriginalListening: '/services/app/EnglishListening/GetOriginalListening',
  GetEngFinishProgress: '/services/app/EnglishEvaluation/GetEngFinishProgress',
  GetEngSelfQuestion: '/services/app/EnglishCommon/GetEngSelfQuestion',
  SaveEngSelfQuestion: '/services/app/EnglishCommon/SaveEngSelfQuestion',
  PdfEvaluationReport: "/services/app/EnglishReport/PdfEvaluationReport"
}
//战备体检答题页
export function getExamSectionsAsync(parameter) {
  return axios({
    url: api.GetExamSectionsAsync,
    method: "get",
    params: parameter,
  });
}
//战备体检展开试题
export function getEngExamQuestionInfos(parameter) {
  return axios({
    url: api.GetEngExamQuestionInfos,
    method: "get",
    params: parameter,
  });
}
// 提交答案接口
export function saveEngExam (parameter) {
  return axios({
    url: api.SaveEngExam,
    method: 'post',
    data: parameter
  })
}
//战备体检报告
export function getEngReport(parameter) {
  return axios({
    url: api.GetEngReport,
    method: "get",
    params: parameter,
  });
}

//战备体检答题记录
export function getSectionAnswerRecords(parameter) {
  return axios({
    url: api.GetSectionAnswerRecords,
    method: "get",
    params: parameter,
  });
}

export function getExamRecord (parameter) {
  return axios({
    url: api.GetExamRecord,
    method: 'get',
    params: parameter
  })
}

export function getOriginalListening (parameter) {
  return axios({
    url: api.GetOriginalListening,
    method: 'get',
    params: parameter
  })
}

//测评完成页面
export function getEngFinishProgress (parameter) {
  return axios({
    url: api.GetEngFinishProgress,
    method: 'get',
    params: parameter
  })
}

//自评分试题
export function getEngSelfQuestion (parameter) {
  return axios({
    url: api.GetEngSelfQuestion,
    method: 'get',
    params: parameter
  })
}

//提交自评分
export function saveEngSelfQuestion(parameter) {
  return axios({
    url: api.SaveEngSelfQuestion,
    method: "post",
    data: parameter,
  });
}

// 战备体检 pdf下载 
export function pdfEvaluationReport(parameter) {
  return axios({
    url: api.PdfEvaluationReport,
    method: "post",
    params: parameter,
  });
}
