import { render, staticRenderFns } from "./end-form.vue?vue&type=template&id=aa054208&scoped=true&"
import script from "./end-form.vue?vue&type=script&lang=js&"
export * from "./end-form.vue?vue&type=script&lang=js&"
import style0 from "./end-form.vue?vue&type=style&index=0&id=aa054208&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa054208",
  null
  
)

export default component.exports